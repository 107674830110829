import { useState, useEffect, useRef } from 'react'
import { Button, Form, Input, Message } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
// import Link from '@/components/link'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import UserCountDown from '@/features/user/components/count-down'
import { UniversalSecurityVerificationRules } from '@/features/user/utils/validate'
import {
  postMemberSafeVerifyEmailSend,
  postMemberSafeVerifyPhoneSend,
  postMemberSafeVerifyWithdrawalEmailSend,
  postMemberSafeVerifyWithdrawalPhoneSend,
  postMemberUniversalSecurityVerification,
  postMemberQueryWorkOrderStatus,
} from '@/apis/user'
import {
  UserEnabledStateTypeEnum,
  UserVerifyTypeEnum,
  UserSendValidateCodeBusinessTypeEnum,
  RestPasswordInputEnum,
  UserValidateMethodEnum,
} from '@/constants/user'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { UserInformationDesensitization } from '@/features/user/utils/common'
import Icon from '@/components/icon'
import { ISafeVerifySendWithdrawalDataProps } from '@/typings/assets'
import UserInputStyle from '@/features/user/common/new-input-style/index'
import { useUserStore } from '@/store/user'
import { getPersonalAccountSecurityPath, getSafetyicationPath } from '@/helper/route'
import { useCustomerServiceLink } from '@/hooks/features/customer-service/index'
import styles from './index.module.css'

const FormItem = Form.Item
interface UniversalSecurityVerificationProps {
  /** 是否显示弹窗 */
  isShow: boolean
  /** 是否需要两项验证弹窗 */
  isVerificationTips?: boolean
  /** 业务类型 */
  businessType?: number | string
  /** 提币获取验证码入参 */
  withdrawalData?: ISafeVerifySendWithdrawalDataProps
  /** 关闭弹窗 */
  onClose?(close: boolean): void
  /** 验证通过函数 */
  onSuccess(isTrue: boolean, option?: { [key: string]: string | number }): void
  /** 验证失败函数 */
  onError?(): void
  /** 当前验证模式 */
  verifyMethod?: string
}

function UniversalSecurityVerification({
  isShow = false,
  isVerificationTips = false,
  businessType,
  withdrawalData,
  onClose,
  onSuccess,
  onError,
  verifyMethod,
}: UniversalSecurityVerificationProps) {
  const defaultShow = {
    mobileVerifyCode: false,
    emailVerifyCode: false,
    googleVerifyCode: false,
    withdrawalPwd: false,
  }

  type RuleCalibration = {
    mobileVerifyCode: boolean | null
    emailVerifyCode: boolean | null
    googleVerifyCode: boolean | null
    withdrawalPwd: boolean | null
  }

  const defaultruleCalibration: RuleCalibration = {
    mobileVerifyCode: null,
    emailVerifyCode: null,
    googleVerifyCode: null,
    withdrawalPwd: null,
  }

  const [workOrderPopUp, setWorkOrderPopUp] = useState<boolean>(false)
  const isEmailSend = useRef<boolean>(false)
  const isPhoneSend = useRef<boolean>(false)
  const [passwordShow, setPasswordShow] = useState<boolean>(false)

  const { getBaseInfo, baseInfoResult, turnOnVerification } = usePersonalCenterStore()
  const [ruleCalibration, setRuleCalibration] = useState(defaultruleCalibration)
  const [verifyCodeStyle, setVerifyCodeStyle] = useState(defaultShow)
  const [mobileSend, setMobileSendSend] = useState<boolean>(false)
  const [emailSend, setEmailSend] = useState<boolean>(false)
  const [googleVerifyCode, setGoogleVerifyCode] = useState('')
  const { merchantSettings } = useUserStore()
  const customerServiceLinks = useCustomerServiceLink()

  const [form] = Form.useForm()

  const rule = UniversalSecurityVerificationRules(isEmailSend, isPhoneSend)

  useEffect(() => {
    if (isShow) {
      getBaseInfo()
    }
  }, [isShow])

  // const handleValidateChange = () => {
  //   form
  //     .validate()
  //     .then(() => setDisabled(false))
  //     .catch(() => setDisabled(true))
  // }

  const handleToSecurityItem = async () => {
    const options = {
      type: baseInfoResult?.verifyInfo?.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone,
      account: baseInfoResult?.verifyInfo?.email || baseInfoResult?.verifyInfo?.mobileNumber,
      mobileCountryCode: baseInfoResult?.verifyInfo?.mobileNumber
        ? baseInfoResult?.verifyInfo?.mobileCountryCd
        : undefined,
    }
    const res = await postMemberQueryWorkOrderStatus(options)
    res.isOk && res.data?.isBeginManualVerifyProcess ? setWorkOrderPopUp(true) : link(getSafetyicationPath())
  }

  const isWithdrawType = businessType === UserSendValidateCodeBusinessTypeEnum.withdraw
  const handleSendEmailValidateCode = async () => {
    const res = isWithdrawType
      ? await postMemberSafeVerifyWithdrawalEmailSend({
          type: businessType! as unknown as number,
          email: baseInfoResult?.verifyInfo?.email,
          address: withdrawalData?.address || '',
          quantity: withdrawalData?.quantity || 0,
          currencyCode: withdrawalData?.currencyCode || '',
          memo: withdrawalData?.memo || '',
        })
      : await postMemberSafeVerifyEmailSend({
          type: businessType! as unknown as number,
          email: baseInfoResult?.verifyInfo?.email,
        })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isEmailSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleSendPhoneValidateCode = async () => {
    const res = isWithdrawType
      ? await postMemberSafeVerifyWithdrawalPhoneSend({
          type: businessType! as unknown as number,
          mobileCountryCode: baseInfoResult?.verifyInfo?.mobileCountryCd,
          mobile: baseInfoResult?.verifyInfo?.mobileNumber,
          address: withdrawalData?.address || '',
          quantity: withdrawalData?.quantity || 0,
          currencyCode: withdrawalData?.currencyCode || '',
          memo: withdrawalData?.memo || '',
        })
      : await postMemberSafeVerifyPhoneSend({
          type: businessType! as unknown as number,
          mobileCountryCode: baseInfoResult?.verifyInfo?.mobileCountryCd,
          mobile: baseInfoResult?.verifyInfo?.mobileNumber,
        })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isPhoneSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleClose = () => {
    form.resetFields()
    onClose && onClose(false)
    setRuleCalibration({
      mobileVerifyCode: null,
      emailVerifyCode: null,
      googleVerifyCode: null,
      withdrawalPwd: null,
    })
    setVerifyCodeStyle({
      mobileVerifyCode: false,
      emailVerifyCode: false,
      googleVerifyCode: false,
      withdrawalPwd: false,
    })
  }

  const onContinue = () => {
    onClose && onClose(false)
    link(getPersonalAccountSecurityPath())
  }

  const valOnBlur = val => {
    switch (val) {
      case RestPasswordInputEnum.mobileVerifyCode:
        form
          .validate([val])
          .then(() => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              mobileVerifyCode: false,
            }))
          })
          .catch(e => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              mobileVerifyCode: true,
            }))
          })
        break
      case RestPasswordInputEnum.emailVerifyCode:
        form
          .validate([val])
          .then(() => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              emailVerifyCode: false,
            }))
          })
          .catch(e => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              emailVerifyCode: true,
            }))
          })
        break
      case RestPasswordInputEnum.googleVerifyCode:
        form
          .validate([val])
          .then(() => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              googleVerifyCode: false,
            }))
          })
          .catch(e => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              googleVerifyCode: true,
            }))
          })
        break
      case RestPasswordInputEnum.withdrawalPwd:
        form
          .validate([val])
          .then(() => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              withdrawalPwd: false,
            }))
          })
          .catch(e => {
            setRuleCalibration(prevRule => ({
              ...prevRule,
              withdrawalPwd: true,
            }))
          })
        break
      default:
    }
  }

  const valueChange = val => {
    switch (val) {
      case RestPasswordInputEnum.mobileVerifyCode:
        setVerifyCodeStyle(prevStyle => ({
          ...prevStyle,
          mobileVerifyCode: true,
        }))
        break
      case RestPasswordInputEnum.emailVerifyCode:
        setVerifyCodeStyle(prevStyle => ({
          ...prevStyle,
          emailVerifyCode: true,
        }))
        break
      case RestPasswordInputEnum.googleVerifyCode:
        setVerifyCodeStyle(prevStyle => ({
          ...prevStyle,
          googleVerifyCode: true,
        }))
        break
      case RestPasswordInputEnum.withdrawalPwd:
        setVerifyCodeStyle(prevStyle => ({
          ...prevStyle,
          withdrawalPwd: true,
        }))
        break
      default:
    }
  }

  const handlePaste = () => {
    navigator.clipboard.readText().then(text => {
      text && setGoogleVerifyCode(text)
      text && form.setFieldValue('googleVerifyCode', text)
    })
  }

  const affixHandlePaste = () => {
    setVerifyCodeStyle(prevStyle => ({
      ...prevStyle,
      googleVerifyCode: true,
    }))
    navigator.clipboard.readText().then(text => {
      text && setGoogleVerifyCode(text)
      text && form.setFieldValue('googleVerifyCode', text)
    })
  }

  const handleSecurityVerification = async options => {
    const res = await postMemberUniversalSecurityVerification(options)
    if (res.data?.isSuccess) {
      onSuccess(true, options)
      handleClose()
      return
    } else {
      setRuleCalibration({
        mobileVerifyCode: true,
        emailVerifyCode: true,
        googleVerifyCode: true,
        withdrawalPwd: true,
      })
    }

    onSuccess(false)
    onError && onError()
  }

  const { run, loading } = useRequest(handleSecurityVerification, { manual: true })

  const onSubmit = async values => {
    const options = {
      operateType: businessType,
      account: baseInfoResult?.verifyInfo?.email || baseInfoResult?.verifyInfo?.mobileNumber,
      mobileCountryCode: baseInfoResult?.verifyInfo?.mobileCountryCd,
      ...values,
    }

    run(options)
  }

  const onSubmitFailed = val => {
    const truleCalibration = {
      mobileVerifyCode: false,
      emailVerifyCode: false,
      googleVerifyCode: false,
      withdrawalPwd: false,
    }
    const keys = Object.keys(val)
    const updatedRules = { ...truleCalibration }
    keys.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(updatedRules, key)) {
        updatedRules[key] = true
      }
    })
    setRuleCalibration(updatedRules)
  }

  return (
    <UserPopUp
      title={<div style={{ textAlign: 'left' }}>{t`user.pageContent.title_06`}</div>}
      autoFocus={false}
      className="user-popup"
      maskClosable={false}
      visible={isShow}
      closeIcon={<Icon name="close" />}
      onCancel={handleClose}
      footer={null}
    >
      <div className={`universal-security-verification user-form-style ${styles.scoped}`}>
        <div className="universal-security-verification-wrap">
          <Form
            form={form}
            layout="vertical"
            onSubmit={onSubmit}
            autoComplete="off"
            validateTrigger="onBlur"
            onSubmitFailed={onSubmitFailed}
            // onChange={handleValidateChange}
          >
            {/* {isTrade && baseInfoResult.setTradePwdInd === UserEnabledStateTypeEnum.enable && (
              <FormItem
                label={t`user.account_security.settings_08`}
                field="fundPassword"
                requiredSymbol={false}
                rules={[rule.fundPassword]}
              >
                <Input placeholder={t`user.account_security.settings_09`} />
              </FormItem>
            )} */}

            {baseInfoResult?.isEmailEnable &&
              baseInfoResult?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable && (
                <FormItem
                  style={{ marginTop: 0, marginBottom: 24 }}
                  extra={t({
                    id: 'user.register.verification_01',
                    values: {
                      0: `${UserInformationDesensitization(baseInfoResult?.verifyInfo?.email)}`,
                    },
                  })}
                  field="emailVerifyCode"
                  requiredSymbol={false}
                  rules={[rule.emailCode]}
                >
                  {verifyCodeStyle.emailVerifyCode ? (
                    <UserInputStyle
                      label={t`user.field.reuse_03`}
                      onBlur={() => {
                        valOnBlur('emailVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      ruleCalibration={ruleCalibration.emailVerifyCode}
                      onChange={value => {}}
                      placeholder={t`user.field.reuse_20`}
                      suffix={isShow && <UserCountDown onSend={handleSendEmailValidateCode} otherSend={emailSend} />}
                    />
                  ) : (
                    <Input
                      onFocus={() => {
                        valueChange('emailVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      placeholder={t`user.field.reuse_20`}
                      suffix={
                        <button
                          type="button"
                          className="send-button"
                          onClick={() => {
                            setEmailSend(true)
                          }}
                        >
                          {t`user.field.reuse_31`}
                        </button>
                      }
                    />
                  )}
                </FormItem>
              )}

            {baseInfoResult?.isMobileEnable &&
              baseInfoResult?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable && (
                <FormItem
                  style={{ marginTop: 0, marginBottom: 24 }}
                  extra={t({
                    id: 'user.universal_security_verification_03',
                    values: {
                      0: `+${baseInfoResult?.verifyInfo?.mobileCountryCd} ${UserInformationDesensitization(
                        baseInfoResult?.verifyInfo?.mobileNumber
                      )}`,
                    },
                  })}
                  field="mobileVerifyCode"
                  requiredSymbol={false}
                  rules={[rule.phoneCode]}
                >
                  {verifyCodeStyle.mobileVerifyCode ? (
                    <UserInputStyle
                      label={t`user.field.reuse_04`}
                      onBlur={() => {
                        valOnBlur('mobileVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      ruleCalibration={ruleCalibration.mobileVerifyCode}
                      onChange={value => {}}
                      placeholder={t`user.field.reuse_21`}
                      suffix={isShow && <UserCountDown onSend={handleSendPhoneValidateCode} otherSend={mobileSend} />}
                    />
                  ) : (
                    <Input
                      onFocus={() => {
                        valueChange('mobileVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      placeholder={t`user.field.reuse_21`}
                      suffix={
                        <button
                          type="button"
                          className="send-button"
                          onClick={() => {
                            setMobileSendSend(true)
                          }}
                        >
                          {t`user.field.reuse_31`}
                        </button>
                      }
                    />
                  )}
                </FormItem>
              )}

            {baseInfoResult?.isGoogleEnable &&
              baseInfoResult?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable && (
                <FormItem
                  style={{ marginTop: 0, marginBottom: 24 }}
                  extra={`${t`user.safety_verification_06`}`}
                  field="googleVerifyCode"
                  requiredSymbol={false}
                  rules={[rule.googleKey]}
                >
                  {verifyCodeStyle.googleVerifyCode ? (
                    <UserInputStyle
                      label={t`user.field.reuse_05`}
                      onBlur={() => {
                        valOnBlur('googleVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      defautValue={googleVerifyCode}
                      placeholder={t`user.safety_verification_05`}
                      ruleCalibration={ruleCalibration.googleVerifyCode}
                      onChange={value => {
                        setGoogleVerifyCode(value)
                      }}
                      suffix={
                        <span
                          className="affix"
                          onClick={() => handlePaste()}
                        >{t`features_user_withdraw_universal_security_verification_index_uabznjhxyw`}</span>
                      }
                    />
                  ) : (
                    <Input
                      onFocus={() => {
                        valueChange('googleVerifyCode')
                      }}
                      type="number"
                      maxLength={6}
                      placeholder={t`user.safety_verification_05`}
                      suffix={
                        <span
                          className="affix"
                          onClick={() => affixHandlePaste()}
                        >{t`features_user_withdraw_universal_security_verification_index_uabznjhxyw`}</span>
                      }
                    />
                  )}
                </FormItem>
              )}

            {verifyMethod !== UserValidateMethodEnum.pwd &&
              baseInfoResult?.isWithdrawPwdEnable &&
              baseInfoResult?.verifyInfo?.isOpenWithdrawalPwd === UserEnabledStateTypeEnum.enable && (
                <FormItem
                  style={{ marginTop: 0, marginBottom: 24 }}
                  field="withdrawalPwd"
                  requiredSymbol={false}
                  rules={[rule.coinWithdrawal]}
                  extra={t`features_user_universal_security_verification_index_hd8o22shh5`}
                >
                  {verifyCodeStyle.withdrawalPwd ? (
                    <UserInputStyle
                      label={t`features_user_withdraw_safety_verification_index_f_ercdp6bu`}
                      onBlur={() => {
                        valOnBlur('withdrawalPwd')
                      }}
                      type={passwordShow ? 'number' : 'password'}
                      maxLength={6}
                      placeholder={t`features_user_withdraw_universal_security_verification_index_afklyonupy`}
                      ruleCalibration={ruleCalibration.withdrawalPwd}
                      onChange={value => {}}
                      suffix={
                        <Icon
                          onClick={() => setPasswordShow(!passwordShow)}
                          name={passwordShow ? 'eyes_open' : 'eyes_close'}
                        />
                      }
                    />
                  ) : (
                    <Input
                      onFocus={() => {
                        valueChange('withdrawalPwd')
                      }}
                      type={passwordShow ? 'number' : 'password'}
                      maxLength={6}
                      placeholder={t`features_user_withdraw_universal_security_verification_index_afklyonupy`}
                      suffix={
                        <Icon
                          onClick={() => setPasswordShow(!passwordShow)}
                          name={passwordShow ? 'eyes_open' : 'eyes_close'}
                        />
                      }
                    />
                  )}
                </FormItem>
              )}

            <div className="safety-items">
              <span
                className="customize-link-style"
                onClick={handleToSecurityItem}
              >{t`user.universal_security_verification_06`}</span>
            </div>

            <FormItem style={{ marginBottom: 0 }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                // disabled={disabled}
              >
                {t`user.field.reuse_10`}
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>

      <UserPopUp
        className="user-popup user-popup-tips"
        visible={workOrderPopUp}
        closeIcon={<Icon name="close" />}
        okText={t`user.safety_verification_14`}
        cancelText={t`user.field.reuse_48`}
        onOk={() => {
          link(customerServiceLinks.toString())
          setWorkOrderPopUp(false)
        }}
        onCancel={() => setWorkOrderPopUp(false)}
      >
        <UserPopupTipsContent slotContent={<p>{t`user.safety_verification_13`}</p>} />
      </UserPopUp>

      <UserPopUp
        className="user-popup user-popup-tips"
        visible={isShow && isVerificationTips && turnOnVerification}
        closeIcon={<Icon name="close" />}
        okText={t`user.universal_security_verification_08`}
        cancelText={t`user.field.reuse_09`}
        onOk={onContinue}
        onCancel={handleClose}
      >
        <UserPopupTipsContent
          slotContent={
            <>
              <p>{t`user.universal_security_verification_07`}</p>
              {merchantSettings.isEmailEnable && <p>{t`user.universal_security_verification_09`}</p>}
              {merchantSettings.isMobileEnable && <p>{t`user.universal_security_verification_10`}</p>}
              {merchantSettings.isGoogleEnable && <p>{t`user.universal_security_verification_11`}</p>}
              {merchantSettings.isWithdrawPwdEnable && (
                <p>{t`features_user_withdraw_universal_security_verification_index_njwbtnedvf`}</p>
              )}
            </>
          }
        />
      </UserPopUp>
    </UserPopUp>
  )
}

export default UniversalSecurityVerification
